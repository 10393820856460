<template>
  <div id="signup-flow" class="tw-h-full tw-flex tw-flex-col">
    <v-container class="tw-py-16">
      <v-row>
        <v-col>
          <v-img
            :width="46"
            src="@/assets/lets-jetty.svg"
            class="tw-block tw-mx-auto tw-mb-5"
          ></v-img>
          <h1
            class="tw-text-dark-green tw-leading-none tw-text-64 tw-font-bebas-neue"
          >
            LET'S JETTY
          </h1>
        </v-col>
      </v-row>
    </v-container>

    <v-sheet class="tw-w-full tw-bg-white tw-rounded-3xl tw-py-8 tw-flex-grow">
      <router-view></router-view>
    </v-sheet>
    <v-sheet
      class="disclaimer tw-text-light-grey tw-text-xxs tw-pb-7 tw-leading-5"
    >
      <p class="tw-mb-0">By signing up, you agree to our:</p>
      <div class="d-flex flex-row justify-center align-center">
        <router-link
          to="/privacy-policy"
          class="router-link tw-underline tw-text-light-grey"
          >PRIVACY POLICY</router-link
        >
        <span class="tw-mx-1 tw-my-0">&#9679;</span>
        <router-link
          to="/terms-and-conditions"
          class="router-link tw-underline tw-text-light-grey"
          >TERMS AND CONDITIONS</router-link
        >
      </div>
    </v-sheet>
    <global-alert v-if="globalAlert.show" />
  </div>
</template>

<script>
import GlobalAlert from "@/components/alerts/GlobalAlert";

export default {
  name: "AuthFlow",
  data() {
    return {
      email: null,
    };
  },
  components: {
    GlobalAlert,
  },
  methods: {
    progressSignupOrLogin() {
      this.$store
        .dispatch("auth-user-check/find", {
          query: {
            email: this.email,
          },
        })
        .then((res) => {
          if (res.total > 0) {
            this.$router.push({ path: "/login", query: { email: this.email } });
          } else {
            this.$router.push({
              path: "/signup",
              query: { email: this.email },
            });
          }
        });
    },
  },
  computed: {
    globalAlert() {
      return this.$store.state.meta.globalAlert;
    },
  },
};
</script>

<style lang="scss" scoped>
#signup-flow {
  background: linear-gradient(
    180deg,
    rgba(230, 255, 161, 1) 53%,
    rgba(255, 255, 255, 1) 53%
  );
}
</style>
